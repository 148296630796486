<template>
	<v-switch
		:key="key"
		ref="switch"
		:color="color"
		:hide-details="hideDetails"
		:input-value="value"
		v-bind="$attrs"
		@change="onChange"
		@keyup.native="onKeyup"
		v-on="$listeners"
	>
		<!-- Default slot -->
		<template v-if="$slots.default">
			<slot></slot>
		</template>

		<!-- Custom slots -->
		<template v-for="slotName in Object.keys($slots)" v-slot:[slotName]>
			<slot :name="slotName"></slot>
		</template>
	</v-switch>
</template>

<script>
/**
 * @displayName w-switch
 *
 * @since 0.3.2
 */
export default {
	name: 'WSwitch',
	props: {
		color: {
			required: false,
			type: String,
			default: 'primary'
		},
		hideDetails: {
			required: false,
			type: Boolean,
			default: true
		},
		value: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			key: null
		}
	},
	computed: {
		copiedValue: function () {
			return this.value
		}
	},
	watch: {
		value: {
			handler: function () {
				this.key = Math.floor(Math.random() * 100)
			},
			immediate: true
		}
	},
	methods: {
		onChange: function (toggleValue) {
			this.$emit('change', toggleValue)
			this.$emit('input', toggleValue)
		},
		onKeyup: function (event) {
			this.$emit('keyup', event)
		}
	}
}
</script>
